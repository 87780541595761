import React, { Fragment, useEffect, useState } from "react";
import "moment-timezone";
import moment from "moment/moment";
import "src/index.css";
import AuthService from "src/service/api";
import OrderHeader from "src/components/Order/OrderHeader";
import { useNavigate } from "react-router-dom";
import OrderDetails from "src/components/Order/OrderDetails/OrderDetails";
import OrderProduct from "src/components/Order/OrderProduct";
import OrderCharges from "src/components/Order/OrderCharges";
import PaymentIcon from "src/images/payments.png";
import { groupByCategory } from "src/helper/groupByCategory";

export const Order = () => {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState({});
  const [timeZoneId, setTimeZoneId] = useState("America/Los_Angeles");
  const productsData = groupByCategory(orderData?.products);

  useEffect(() => {
    let parsedParams = (url) => {
      return url.split("/");
    };

    const params = parsedParams(window.location.pathname);
    const businessId = params[1];
    const orderId = params[2];

    if (!businessId || !orderId) {
      navigate("/404");
    }

    const fetchData = async () => {
      try {
        const response = await AuthService.getOrderData(businessId, orderId);
        if (response?.data?.data?.business) {
          document.title = `Orders co | ${response.data.data.business[0].companyName}`;
        } else {
          document.title = `Orders co`;
        }
        if (response) {
          setOrderData(response.data.data || {});
          setTimeZoneId(
            response?.data?.data?.business[0]?.timezone?.timeZoneId
          );
        }
      } catch (e) {
        document.title = `Orders co`;
        setOrderData({});
        setTimeZoneId("America/Los_Angeles");
      }
    };
    fetchData().then();
  }, []);

  const convertPayment = (method) => {
    const PAYMENTS = {
      google_pay: "Google Pay",
      apple_pay: "Apple Pay",
      cash: "Cash",
      card: "Card",
    };
    return PAYMENTS[method];
  };

  return (
    <>
      {orderData && Object.keys(orderData)?.length > 0 && (
        <div id="receipt">
          <OrderHeader orderData={orderData} />
          <OrderDetails orderData={orderData} timeZoneId={timeZoneId} />
          <table id="table">
            <tbody>
              <tr>
                <td className="items">Items:</td>
              </tr>
              {Object.keys(productsData)?.length > 0 ? (
                Object.keys(productsData)?.map((keys, index) => {
                  return (
                    <>
                      <Fragment key={keys}>
                        {productsData[keys]?.categoryName ? (
                          <>
                            {index !== 0 ? (
                              <div
                                style={{
                                  marginBottom: "16px",
                                  marginTop: "16px",
                                  borderTop: "1px solid rgba(0, 0, 0, 0.4)",
                                }}
                              ></div>
                            ) : (
                              <></>
                            )}
                            <tr
                              style={{
                                marginBottom: "3px",
                              }}
                            >
                              <td
                                style={{
                                  marginLeft: "29px",
                                }}
                                className="product product-name-longer"
                              >
                                {productsData[
                                  keys
                                ]?.categoryName?.toUpperCase()}
                              </td>
                              <td
                                className="product  product-longer-price"
                                style={{
                                  width: "100%",
                                }}
                              ></td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                      </Fragment>
                      {productsData[keys]?.items?.map((product) => {
                        return (
                          <Fragment key={product?._id}>
                            <OrderProduct product={product} />
                          </Fragment>
                        );
                      })}
                    </>
                  );
                })
              ) : (
                // orderData?.products.map((product, index) => {
                //   return (
                // <Fragment key={product?._id}>
                //   <OrderProduct product={product} index={index} />
                // </Fragment>
                //   );
                // })
                <></>
              )}
            </tbody>
          </table>
          <hr className="line-header" />
          <table id="table">
            <tbody>
              <OrderCharges orderData={orderData} />
            </tbody>
          </table>
          <>
            <hr className="line-header" />
            <div className="payment-container">
              <div>
                <img src={PaymentIcon} alt="payment icon" />
              </div>
              <div>
                {orderData?.payment &&
                Object.keys(orderData?.payment)?.length ? (
                  <p className="payment-title">
                    Payments: by{" "}
                    {convertPayment(
                      orderData?.payment?.payment_method_details?.card?.wallet
                        ?.type || orderData?.paymentMethod
                    )}
                  </p>
                ) : (
                  <p className="payment-title">
                    Payments: by{" "}
                    {convertPayment(
                      orderData?.payment?.payment_method_details?.card?.wallet
                        ?.type || orderData?.paymentMethod
                    )}
                  </p>
                )}
                <p className="cart-number-title">
                  Date:{" "}
                  {moment(orderData?.formattedCreatedAt).format(
                    "hh:mm A - ddd, MMM Do"
                  )}
                </p>
                {orderData?.payment &&
                Object.keys(orderData?.payment)?.length &&
                orderData?.payment?.payment_method_details?.card?.last4 ? (
                  <p className="cart-number">
                    Card no:{" "}
                    {`XXXX XXXX XXXX ${orderData?.payment?.payment_method_details?.card?.last4}`}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};
